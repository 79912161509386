import { useAuth } from '../context/auth.context';
import { LoginMode } from "../enums/auth.enum";
import { Card } from "react-bootstrap"
import i18n from "../i18n"
import EmailStepComponent from '../components/loginSteps/emailStep.component'
import PasswordStepComponent from '../components/loginSteps/passwordStep.component'
import useDocument from '../hooks/useDocument.hooks'

function LoginPage() {

    const { t } = i18n;
    const { settings } = useAuth();

    useDocument({ title: t('_page_title.login') })

    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_sign_in.title')}</h1></Card.Title>
                <Card.Text className="text-start">{t('_sign_in.description')}</Card.Text>

                <EmailStepComponent />
                {settings.loginMode === LoginMode.BASIC &&
                    <PasswordStepComponent />
                }

            </Card.Body>
        </>
    )
}

export default LoginPage