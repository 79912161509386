import axios, { AxiosInstance } from 'axios';
import initInterceptors from './interceptors';
import config from "../config";
import i18n from '../i18n';

function getToken(): string {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('t');
    return token ?? '';
}

const formatToken = (token: string) => {
    return `Bearer ${token}`;
};

const baseURL = config.API_URL;

export default class apiService {
   
    private api!: AxiosInstance;

    private setApi() {
        const {language} = i18n;
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        this.api = axios.create({
            baseURL,
            timeout: 300000,
            headers: {
                Authorization: formatToken(getToken()),
                Language: language,
                Timezone: timeZone
            },
        });

        initInterceptors(this.api);

    }

    constructor() {
        this.setApi()
    }
    
    public validateEmail = (data: {email: string}) => {
        
        return this.api.post(`/validateEmail`, {
            data
        });
    }

    public login = (data: {email: string, password: string}) => {
        return this.api.post(`/login`, {
            data
        });
    }

    public logout = () => {
        return this.api.get(`/logout`);
    }

    public forgotPassword = (data: {email: string}) => {
        return this.api.post(`/forgotPassword`, {
            data
        });
    }

    public resetPassword = (data: {token: string, new_password: string, confirm_password: string}) => {
        return this.api.post(`/resetPassword`, {
            data
        });
    }
}